import * as React from 'react';
import Text from '../../../shared/Text';
import ReactTextHelper from '@c42/helpers/lib/utils/reactText';

const LoginStepTotpStorageNodeWarning = () => {
  const bodyText = Text.get('login_totp_storage_node_warning_description');
  const bodyElem = ReactTextHelper.createElementsFromText(bodyText);

  const linkUrl = 'https://www.code42.com/r/support/contact-support';
  const linkText = Text.get('login_totp_storage_node_warning_more_info', { url: linkUrl });
  const linkElem = ReactTextHelper.createElementsFromText(linkText);

  return (
    <div className="step-totp-sn-warning">
      <p className="totp-sn-warning-body">{bodyElem}</p>
      <p className="totp-sn-warning-link">{linkElem}</p>
    </div>
  );
};

export default LoginStepTotpStorageNodeWarning;
