import * as React from 'react';
import ReactRouter from 'react-router';
import { FORGOT_PASSWORD_INDEX } from '../constants/routes';
import LoginWrapper from './login-wrapper';
import { STEP_USERNAME, STEP_PASSWORD } from '../modules/auth-data';

type EmptyObject = Record<string, never>;

interface MainProps extends ReactRouter.RouteComponentProps<EmptyObject, EmptyObject> {
  children: React.ReactNode[];
  currentStep: string;
  isInitialized: boolean;
  isValidatingToken: boolean;
  isStorageNode: boolean;
  goToLogin: () => void;
  initializeApplication: (pathname: string, query: any) => void;
  toggleWorkingScreen: (show: boolean) => void;
}

export default class Main extends React.Component<MainProps, EmptyObject> {
  componentWillMount() {
    this.props.toggleWorkingScreen(true);
  }

  componentDidMount() {
    const { initializeApplication, location } = this.props;
    const { pathname, query } = location;
    initializeApplication(pathname, query);
  }

  componentWillUpdate(nextProps: MainProps) {
    const { children, isInitialized, goToLogin } = nextProps;

    if (isInitialized && children == null) {
      goToLogin();
    }
  }

  isForgotPasswordVisible(): boolean {
    const { currentStep, location } = this.props;

    // Show link on only the first two steps of login form.
    const allowedSteps = [STEP_USERNAME, STEP_PASSWORD];
    const isStepAllowed = allowedSteps.indexOf(currentStep) > -1;

    // Show link on all routes except the forgotten password route.
    const isRouteAllowed = location.pathname !== FORGOT_PASSWORD_INDEX;

    // Do not show "forgot password" on storage node login.
    const showAccountRecoveryLinks = !this.props.isStorageNode;

    return showAccountRecoveryLinks && isStepAllowed && isRouteAllowed;
  }

  render() {
    const { isInitialized, isValidatingToken, children } = this.props;

    if (isValidatingToken) {
      return (
        <LoginWrapper isForgotPasswordVisible={false}>
          <div className="waiting-container">
            <img alt="Loading Spinner" src="/static/images/spinner.gif" />
          </div>
        </LoginWrapper>
      );
    }

    if (isInitialized) {
      return <LoginWrapper isForgotPasswordVisible={this.isForgotPasswordVisible()}>{children}</LoginWrapper>;
    }

    return <div />;
  }
}
