import * as React from 'react';
import Text from '../../shared/Text';
import { ComplexityError, minLength } from '@c42/password-complexity';

interface PasswordComplexityProps {
  errors: ComplexityError[];
}

export const PasswordComplexity: React.StatelessComponent<PasswordComplexityProps> = (props) => {
  const { errors } = props;
  const getComplexityFulfilled = (condition: ComplexityError): string => {
    if (errors.indexOf(ComplexityError.EMPTY) >= 0) {
      return 'complexity-initial';
    }
    if (errors.indexOf(condition) >= 0) {
      return 'complexity-fail';
    }
    return 'complexity-pass';
  };

  return (
    <div data-test="password-complexity" className="password-complexity">
      <p className="complexity-header">{Text.get('_validate_password_header')}:</p>
      <ul>
        <li className={getComplexityFulfilled(ComplexityError.LENGTH)} data-test="complexity-length">
          {Text.get('_validate_password_minlength', { MinLength: minLength.toString() })}
        </li>
        <li className={getComplexityFulfilled(ComplexityError.NUMBER)} data-test="complexity-number">
          {Text.get('_validate_password_number')}
        </li>
        <li className={getComplexityFulfilled(ComplexityError.LOWER)} data-test="complexity-lower">
          {Text.get('_validate_password_lower')}
        </li>
        <li className={getComplexityFulfilled(ComplexityError.UPPER)} data-test="complexity-upper">
          {Text.get('_validate_password_upper')}
        </li>
        <li className={getComplexityFulfilled(ComplexityError.WHITESPACE)} data-test="complexity-whitespace">
          {Text.get('_validate_password_whitespace')}
        </li>
        <li className={getComplexityFulfilled(ComplexityError.REPEATING)} data-test="complexity-repeating">
          {Text.get('_validate_password_repeating')}
        </li>
        <li className={getComplexityFulfilled(ComplexityError.DICTIONARY)} data-test="complexity-dictionary">
          {Text.get('_validate_password_dictionary')}
        </li>
      </ul>
    </div>
  );
};
