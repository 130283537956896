import React from 'react';

const Code42IncydrMimecastLogoSvg = () => (
  <svg
    width="200"
    height="50"
    viewBox="0 0 137 39"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-label="Code42 Incydr a Mimecast Company Logo"
  >
    <path
      d="M16.5134 32.6019C19.1341 32.6019 21.6613 32.9618 24.0547 33.6415C22.3833 34.6145 16.5 38 16.5 38C16.5 38 11.0446 34.8678 8.9453 33.6549C11.3521 32.9751 13.8926 32.6019 16.5134 32.6019ZM7.4611 5.59804C7.50122 5.45142 7.54133 5.29148 7.58144 5.14486C5.95016 6.0912 0 9.51666 0 9.51666V18.2336C1.80511 16.4875 3.39627 14.4749 4.70665 12.209C5.93679 10.0898 6.8594 7.86391 7.47447 5.59804H7.4611ZM33 25.3378C33 26.604 33 28.4833 33 28.4833C33 28.4833 31.1949 29.523 30.2723 30.0561C26.0737 28.1501 21.4206 27.0972 16.5134 27.0972C11.6062 27.0972 6.93963 28.1501 2.75446 30.0561C1.75162 29.4697 0.0133715 28.4833 0.0133715 28.4833V25.3511C3.27593 23.0319 6.15073 20.0996 8.46394 16.5942C8.81159 16.061 9.15924 15.5012 9.49352 14.9414C11.9538 10.7029 13.3578 6.14451 13.799 1.57278C14.6815 1.06629 16.5134 0 16.5134 0C16.5134 0 18.3987 1.09295 19.2678 1.58611C19.7091 6.15784 21.1264 10.7029 23.5733 14.9414C26.0203 19.1666 29.2694 22.6587 33 25.3244V25.3378ZM21.6613 21.9656C20.6183 20.6194 19.6556 19.1933 18.7865 17.7005C18.7865 17.6871 18.7597 17.6605 18.7597 17.6471C17.904 16.1543 17.1552 14.6349 16.5134 13.0887C15.8716 14.6615 15.1094 16.1943 14.2403 17.6871C13.3712 19.1799 12.4084 20.6194 11.3655 21.9656C13.0502 21.739 14.7618 21.6191 16.5134 21.6191C18.265 21.6191 19.9899 21.739 21.6746 21.9656H21.6613ZM25.4453 5.15819C25.5389 5.50473 25.6325 5.85128 25.7395 6.21115C26.3545 8.26377 27.2103 10.2764 28.3335 12.209C29.6438 14.4616 31.2216 16.4742 33 18.1936V9.49C33 9.49 27.0498 6.06454 25.4453 5.14486V5.15819Z"
      fill="#F05323"
    />
    <path
      d="M122.041 19.7209H120.588V21.9406H116.885V19.7209H110.503V16.325L114.85 7.38513H118.962L114.584 16.325H116.885V13.7065H120.588V16.325H122.041V19.7209Z"
      fill="#27267F"
    />
    <path
      d="M124.311 19.2402L129.139 14.1463C129.947 13.2768 130.53 12.6017 130.53 11.896C130.53 11.1902 130.07 10.689 129.242 10.689C128.331 10.689 127.677 11.2925 127.288 12.213L124.086 10.423C125.068 8.1318 127.083 7.07825 129.201 7.07825C131.819 7.07825 134.254 8.72507 134.254 11.6914C134.254 13.5223 133.251 14.893 131.922 16.2841L129.947 18.34H134.499V21.9405H124.311V19.2299V19.2402Z"
      fill="#27267F"
    />
    <path
      d="M41.2444 14.668C41.2444 10.3514 44.5073 7.03735 48.9057 7.03735C51.7902 7.03735 54.2348 8.5512 55.2884 10.8527L53.7132 11.7528C52.9255 10.0344 51.0946 8.89897 48.9057 8.89897C45.6734 8.89897 43.1674 11.4357 43.1674 14.668C43.1674 17.9002 45.6734 20.4369 48.9057 20.4369C51.1253 20.4369 52.8949 19.2709 53.8359 17.5831L55.4418 18.5139C54.245 20.8154 51.7697 22.3088 48.9159 22.3088C44.5176 22.3088 41.2546 18.9845 41.2546 14.668H41.2444Z"
      fill="#27267F"
    />
    <path
      d="M57.3239 14.668C57.3239 10.3514 60.7402 7.03735 64.9851 7.03735C69.23 7.03735 72.6464 10.3617 72.6464 14.668C72.6464 18.9742 69.23 22.2985 64.9851 22.2985C60.7402 22.2985 57.3239 18.9742 57.3239 14.668ZM70.7337 14.668C70.7337 11.4357 68.2276 8.89897 64.9954 8.89897C61.7631 8.89897 59.2571 11.4357 59.2571 14.668C59.2571 17.9002 61.7631 20.4369 64.9954 20.4369C68.2276 20.4369 70.7337 17.9002 70.7337 14.668Z"
      fill="#27267F"
    />
    <path
      d="M90.2602 1.54456V21.9508H88.3372V19.097C87.1098 21.0507 85.0436 22.2986 82.4455 22.2986C78.3336 22.2986 74.9274 18.9742 74.9274 14.668C74.9274 10.3617 78.3438 7.03736 82.4455 7.03736C85.0436 7.03736 87.1098 8.29549 88.3372 10.2389V1.54456H90.2602ZM88.3475 14.668C88.3475 11.4357 85.8414 8.89898 82.6092 8.89898C79.3769 8.89898 76.8709 11.4357 76.8709 14.668C76.8709 17.9002 79.3769 20.4369 82.6092 20.4369C85.8414 20.4369 88.3475 17.9002 88.3475 14.668Z"
      fill="#27267F"
    />
    <path
      d="M107.721 14.7191C107.721 15.0055 107.69 15.3328 107.659 15.6192H94.8632C95.2723 18.5958 97.5738 20.4267 100.663 20.4267C102.964 20.4267 104.57 19.3425 105.358 17.9514L107.046 18.9435C105.818 20.9279 103.578 22.2985 100.632 22.2985C96.0292 22.2985 92.8788 19.0663 92.8788 14.668C92.8788 10.2696 95.9678 7.03735 100.489 7.03735C105.01 7.03735 107.721 10.7401 107.721 14.7293V14.7191ZM94.8734 13.7576H105.746C105.337 10.556 103.005 8.88874 100.499 8.88874C97.4408 8.88874 95.2518 10.8936 94.8734 13.7576Z"
      fill="#27267F"
    />
    <path
      d="M44.539 36.9563H44.1454C44.0579 36.9563 43.985 36.8841 43.985 36.7975V34.1123C43.985 33.2894 43.7081 33.0295 43.0812 33.0295C42.7313 33.0295 42.3523 33.1595 41.9733 33.3471V36.7975C41.9733 36.8841 41.9004 36.9563 41.8129 36.9563H41.4047C41.3173 36.9563 41.2444 36.8841 41.2444 36.7975V32.6397C41.2444 32.5531 41.3173 32.4809 41.4047 32.4809H41.8129C41.9004 32.4809 41.9733 32.5531 41.9733 32.6397V32.7985C42.4252 32.5387 42.8042 32.4232 43.2853 32.4232C44.2766 32.4232 44.7139 33.0295 44.7139 34.1267V36.8119C44.7139 36.8985 44.641 36.9707 44.5536 36.9707L44.539 36.9563Z"
      fill="#010041"
    />
    <path
      d="M47.4984 37.014C46.2885 37.014 45.6616 36.22 45.6616 35.0218V34.401C45.6616 33.2028 46.2739 32.4088 47.4984 32.4088C48.723 32.4088 49.3353 33.2028 49.3353 34.401V35.0218C49.3353 36.22 48.7084 37.014 47.4984 37.014ZM48.6209 34.401C48.6209 33.5637 48.2856 33.044 47.513 33.044C46.7404 33.044 46.4051 33.5637 46.4051 34.401V35.0218C46.4051 35.8591 46.7404 36.3788 47.513 36.3788C48.2856 36.3788 48.6209 35.8591 48.6209 35.0218V34.401Z"
      fill="#010041"
    />
    <path
      d="M55.7785 32.6686L54.9767 36.451C54.8747 36.9707 54.7726 37.0141 54.2916 37.0141C53.8688 37.0141 53.7522 36.9419 53.621 36.4222L52.9066 33.6503C52.9066 33.6503 52.8921 33.5926 52.8629 33.5926C52.8338 33.5926 52.8338 33.5926 52.8192 33.6503L52.1049 36.4222C51.9882 36.8986 51.857 37.0141 51.4343 37.0141C50.9386 37.0141 50.8512 36.9563 50.7491 36.451L49.9473 32.6686V32.6398C49.9473 32.5531 50.0202 32.481 50.1077 32.481H50.5596C50.6325 32.481 50.6908 32.5531 50.7054 32.6109L51.3905 36.3355C51.4051 36.4366 51.4343 36.4366 51.4634 36.4366C51.4926 36.4366 51.5072 36.4077 51.5363 36.3355L52.3381 33.2028C52.3818 33.0295 52.4985 32.9574 52.6734 32.9574H53.1399C53.3148 32.9574 53.4169 33.0295 53.4752 33.2028L54.277 36.3355C54.2915 36.4077 54.3207 36.4366 54.3499 36.4366C54.379 36.4366 54.3936 36.4366 54.4227 36.3355L55.1079 32.6109C55.1079 32.6109 55.1808 32.481 55.2537 32.481H55.7056C55.7931 32.481 55.866 32.5676 55.866 32.6398V32.6686H55.7785Z"
      fill="#010041"
    />
    <path
      d="M60.385 37.014C60.1226 37.014 59.7436 36.9852 59.4229 36.9274V38.3999C59.4229 38.4866 59.35 38.5588 59.2625 38.5588H58.8397C58.7523 38.5588 58.694 38.4866 58.694 38.3999V32.7841C58.694 32.683 58.7231 32.6253 58.8689 32.5964C59.3062 32.4954 59.8894 32.4088 60.385 32.4088C61.6096 32.4088 62.1781 33.2028 62.1781 34.4299V35.0218C62.1781 36.3066 61.5804 37.0285 60.385 37.0285V37.014ZM61.4492 34.4299C61.4492 33.5781 61.1868 33.0151 60.385 33.0151C60.0351 33.0151 59.6561 33.0584 59.4229 33.1017V36.3355C59.6998 36.3788 60.1226 36.4221 60.385 36.4221C61.1868 36.4221 61.4492 35.9024 61.4492 35.0218V34.4299Z"
      fill="#010041"
    />
    <path
      d="M66.1435 36.9563H65.7644C65.677 36.9563 65.6041 36.8841 65.6041 36.7975V36.6242C65.2105 36.8552 64.8023 37.0285 64.3358 37.0285C63.6361 37.0285 62.9946 36.7253 62.9946 35.7003V35.5992C62.9946 34.7186 63.5194 34.2711 64.9772 34.2711H65.5895V33.939C65.5895 33.2605 65.3563 33.0151 64.7148 33.0151C64.1754 33.0151 63.7818 33.044 63.4611 33.0873H63.4174C63.3445 33.0873 63.2716 33.0584 63.2716 32.9573V32.6686C63.2716 32.582 63.3299 32.5242 63.4465 32.5098C63.7818 32.4521 64.1609 32.4088 64.7294 32.4088C65.881 32.4088 66.333 32.9862 66.333 33.939V36.783C66.333 36.8697 66.2601 36.9419 66.1726 36.9419L66.1435 36.9563ZM65.5749 34.7908H64.9626C63.9713 34.7908 63.6944 35.0073 63.6944 35.5848V35.6859C63.6944 36.2778 63.9713 36.4221 64.4524 36.4221C64.8314 36.4221 65.1667 36.3066 65.5603 36.0901V34.7764L65.5749 34.7908Z"
      fill="#010041"
    />
    <path
      d="M69.2921 33.0295C68.9131 33.0295 68.5632 33.1306 68.155 33.376V36.7975C68.155 36.8841 68.0822 36.9563 67.9947 36.9563H67.5865C67.499 36.9563 67.4261 36.8841 67.4261 36.7975V32.6397C67.4261 32.5531 67.499 32.4809 67.5865 32.4809H67.9801C68.0676 32.4809 68.1405 32.5531 68.1405 32.6397V32.813C68.5487 32.5242 68.9277 32.4232 69.3067 32.4232C69.4233 32.4232 69.4816 32.4665 69.4816 32.5676V32.8996C69.4816 32.9862 69.4233 33.044 69.3067 33.044L69.2921 33.0295Z"
      fill="#010041"
    />
    <path
      d="M72.4995 36.9707C72.3391 36.9996 72.1204 37.014 71.9601 37.014C71.202 37.014 70.8376 36.8119 70.8376 35.9168V33.0728L70.0358 32.9862C69.9483 32.9862 69.8754 32.9285 69.8754 32.8418V32.6253C69.8754 32.5387 69.9483 32.4809 70.0358 32.4809H70.8376V31.6869C70.8376 31.6003 70.8959 31.5426 70.9834 31.5281L71.4061 31.4559H71.4353C71.4353 31.4559 71.5519 31.5137 71.5519 31.6003V32.4954H72.4411C72.5286 32.4954 72.6015 32.5676 72.6015 32.6542V32.9285C72.6015 33.0151 72.5286 33.0873 72.4411 33.0873H71.5519V35.9313C71.5519 36.3788 71.6685 36.451 72.0038 36.451H72.4703C72.5869 36.451 72.6452 36.4799 72.6452 36.5665V36.8552C72.6452 36.9274 72.6015 36.9707 72.4995 36.9996V36.9707Z"
      fill="#010041"
    />
    <path
      d="M77.1353 37.014C75.9253 37.014 75.2985 36.22 75.2985 35.0218V34.401C75.2985 33.2028 75.9107 32.4088 77.1353 32.4088C78.3598 32.4088 78.9721 33.2028 78.9721 34.401V35.0218C78.9721 36.22 78.3452 37.014 77.1353 37.014ZM78.2432 34.401C78.2432 33.5637 77.9079 33.044 77.1353 33.044C76.3627 33.044 76.0274 33.5637 76.0274 34.401V35.0218C76.0274 35.8591 76.3627 36.3788 77.1353 36.3788C77.9079 36.3788 78.2432 35.8591 78.2432 35.0218V34.401Z"
      fill="#010041"
    />
    <path
      d="M82.0041 31.326H81.6105C81.2169 31.326 81.1149 31.4415 81.1149 31.889V32.4809H81.975C82.0624 32.4809 82.1353 32.5531 82.1353 32.6253V32.9429C82.1353 33.0295 82.0624 33.0873 81.975 33.0873H81.1149V36.8119C81.1149 36.8985 81.042 36.9707 80.9545 36.9707H80.5463C80.4589 36.9707 80.386 36.8841 80.386 36.8119V33.0873L79.5842 33.0006C79.4967 33.0006 79.4238 32.9285 79.4238 32.8563V32.6397C79.4238 32.5531 79.4967 32.4954 79.5842 32.4954H80.386V31.9035C80.386 31.0373 80.8087 30.763 81.5376 30.763C81.7563 30.763 81.8875 30.7918 82.0041 30.8063C82.0916 30.8352 82.1645 30.864 82.1645 30.9362V31.2105C82.1645 31.2971 82.1062 31.326 82.0187 31.326H82.0041Z"
      fill="#010041"
    />
    <path
      d="M85.6631 36.9563V30.8496H91.7129C92.9812 30.8496 93.608 31.4704 93.608 32.7264V36.9563H91.9899V32.6109C91.9899 32.3655 91.8587 32.2356 91.6109 32.2356H90.43V36.9563H88.8119V32.2356H87.2521V36.9563H85.6339H85.6631Z"
      fill="#010041"
    />
    <path
      d="M94.7742 28.3088H96.4361V29.8535H94.7742V28.3088ZM94.7888 30.8496H96.4069V36.9563H94.7888V30.8496Z"
      fill="#010041"
    />
    <path
      d="M97.792 36.9563V30.8496H103.842C105.11 30.8496 105.737 31.4704 105.737 32.7264V36.9563H104.119V32.6109C104.119 32.3655 103.988 32.2356 103.74 32.2356H102.559V36.9563H100.941V32.2356H99.381V36.9563H97.7628H97.792Z"
      fill="#010041"
    />
    <path
      d="M106.932 35.0795V32.7119C106.932 31.456 107.559 30.8352 108.827 30.8352H110.344C111.612 30.8352 112.239 31.456 112.239 32.7119V34.4876H108.55V35.1806C108.55 35.426 108.682 35.5559 108.929 35.5559H112.253V36.9419H108.842C107.574 36.9419 106.947 36.3211 106.947 35.0651M108.55 33.2605H110.635V32.6109C110.635 32.3655 110.504 32.2355 110.256 32.2355H108.929C108.682 32.2355 108.55 32.3655 108.55 32.6109V33.2605Z"
      fill="#010041"
    />
    <path
      d="M113.39 35.0795V32.7119C113.39 31.456 114.017 30.8352 115.285 30.8352H118.157V32.2211H115.373C115.125 32.2211 114.994 32.351 114.994 32.5964V35.195C114.994 35.4405 115.125 35.5704 115.373 35.5704H118.157V36.9563H115.285C114.017 36.9563 113.39 36.3355 113.39 35.0795Z"
      fill="#010041"
    />
    <path
      d="M120.883 36.9563C119.615 36.9563 118.988 36.35 118.988 35.1229C118.988 33.8958 119.615 33.2894 120.883 33.2894H122.502V32.6253C122.502 32.3799 122.37 32.25 122.123 32.25H119.309V30.8641H122.21C123.478 30.8641 124.105 31.4848 124.105 32.7408V36.9708H120.883V36.9563ZM120.592 35.1951C120.592 35.4405 120.723 35.5704 120.971 35.5704H122.487V34.531H120.971C120.723 34.531 120.592 34.6609 120.592 34.9063V35.2095V35.1951Z"
      fill="#010041"
    />
    <path
      d="M125.315 36.4366V35.5704H127.997C128.26 35.5704 128.391 35.426 128.391 35.1373C128.391 34.8486 128.274 34.6609 128.041 34.632L126.773 34.4299C125.796 34.2711 125.3 33.7081 125.3 32.7408C125.3 31.4848 125.927 30.8641 127.196 30.8641H129.703V32.25H127.298C127.035 32.25 126.904 32.3944 126.904 32.6831C126.904 32.9718 127.021 33.1595 127.254 33.1884L128.522 33.3905C129.499 33.5493 129.995 34.1123 129.995 35.0796C129.995 36.3356 129.368 36.9563 128.099 36.9563H125.3V36.4366H125.315Z"
      fill="#010041"
    />
    <path
      d="M131.03 35.0796V28.9729H132.648V30.8496H134.659V32.2355H132.648V35.1951C132.648 35.4405 132.779 35.5704 133.027 35.5704H134.776V36.9563H132.925C131.656 36.9563 131.03 36.3355 131.03 35.0796Z"
      fill="#010041"
    />
    <path
      d="M136.569 31.2683C136.569 31.4993 136.38 31.687 136.146 31.687C135.913 31.687 135.709 31.4993 135.709 31.2683C135.709 31.0373 135.899 30.8496 136.146 30.8496C136.394 30.8496 136.569 31.0373 136.569 31.2683ZM135.811 31.2683C135.811 31.456 135.942 31.6003 136.132 31.6003C136.321 31.6003 136.453 31.456 136.453 31.2683C136.453 31.0806 136.321 30.9363 136.132 30.9363C135.942 30.9363 135.811 31.0806 135.811 31.2683ZM136.073 31.4849H135.971V31.0662C135.971 31.0662 136.059 31.0662 136.132 31.0662C136.205 31.0662 136.248 31.0662 136.278 31.0951C136.307 31.1095 136.321 31.1384 136.321 31.1817C136.321 31.225 136.292 31.2683 136.234 31.2827C136.234 31.2827 136.292 31.326 136.307 31.3838C136.307 31.4415 136.321 31.4704 136.336 31.4849H136.234C136.234 31.4849 136.219 31.4271 136.205 31.3838C136.205 31.3405 136.176 31.3116 136.117 31.3116H136.073V31.4849ZM136.073 31.2394H136.117C136.117 31.2394 136.219 31.225 136.219 31.1817C136.219 31.1384 136.19 31.1239 136.132 31.1239C136.103 31.1239 136.088 31.1239 136.073 31.1239V31.2394Z"
      fill="#010041"
    />
  </svg>
);

export default Code42IncydrMimecastLogoSvg;
