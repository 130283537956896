import * as React from 'react';
import Text from '../../../shared/Text';
import FormValidationMessage from '../form-validation-message';
import TextField from '@c42/ui/lib/text-field';
import { sendEmptyTextIfNull } from '../../utils/input-handlers';

type EmptyObject = Record<string, never>;

interface Props {
  username: string | null;
  password: string | null;
  totp: string;
  error: string | null;
  notification: string | null;
  submitForm: () => void;
  onChangePassword: React.FormEventHandler<any>;
  onChangeTotp: () => React.FormEventHandler<any>;
  resetForm: () => void;
  isFormDisabled: boolean;
  isUsingTotp: boolean;
}

export default class LoginStepPassword extends React.Component<Props, EmptyObject> {
  submitForm = (e?: React.FormEvent<any>) => {
    if (e && 'preventDefault' in e) {
      e.preventDefault();
    }
    if (typeof this.props.submitForm === 'function') {
      this.props.submitForm();
    }
  };

  resetForm = (e?: React.FormEvent<any>) => {
    if (e && 'preventDefault' in e) {
      e.preventDefault();
    }
    if (typeof this.props.resetForm === 'function') {
      this.props.resetForm();
    }
  };

  render() {
    const {
      error,
      notification,
      isFormDisabled,
      username,
      password,
      totp,
      onChangePassword,
      onChangeTotp,
      isUsingTotp,
    } = this.props;

    const isPasswordEmpty = !password;
    const isPasswordDirty = password !== null && isPasswordEmpty;
    const hasNotification = !!notification;

    const passwordErrorText =
      isPasswordDirty && isPasswordEmpty ? Text.get('_validate_password_required') : '';

    const isTotpEmpty = !totp;
    const isTotpDirty = totp !== null && isTotpEmpty;

    const totpErrorText = isTotpDirty && isTotpEmpty ? Text.get('_validate_totp_required') : '';

    let validationError = error;
    if (error && isUsingTotp) {
      validationError = Text.get('login_error_incorrect_totp');
    }

    return (
      <form onSubmit={this.submitForm} name="loginFrm" data-test="login-form" noValidate>
        {hasNotification && (
          <div className="success" data-test="notification">
            {notification}
          </div>
        )}
        <FormValidationMessage error={validationError} dataTest="auth-failed-msg" />
        <TextField
          autoComplete="off"
          className="form-control-wrapper"
          data-test="username"
          disabled={true}
          displayErrorInLabel={true}
          floatingLabelText={Text.get('_placeholder_username_or_email')}
          id="username"
          value={username || ''}
        />
        <TextField
          autoComplete="off"
          autoFocus={true}
          className="form-control-wrapper"
          data-test="password"
          disabled={isFormDisabled}
          displayErrorInLabel={true}
          errorText={passwordErrorText}
          floatingLabelText={Text.get('_placeholder_password')}
          id="password"
          onBlur={sendEmptyTextIfNull.bind(null, onChangePassword, password)}
          onChange={onChangePassword}
          type="password"
          value={password || ''}
        />
        {isUsingTotp && (
          <div>
            <TextField
              autoComplete="off"
              className="form-control-wrapper"
              data-test="totp-code"
              disabled={isFormDisabled}
              displayErrorInLabel={true}
              errorText={totpErrorText}
              floatingLabelText={Text.get('_placeholder_enter_code')}
              id="totp"
              onBlur={sendEmptyTextIfNull.bind(null, onChangeTotp, totp)}
              onChange={onChangeTotp}
              type="text"
              maxLength={6}
              value={totp || ''}
            />
            <label htmlFor="totp" className="totp-tooltip">
              {Text.get('login_totp_code_tooltip')}
            </label>
          </div>
        )}
        <div className="form-control-wrapper submit">
          <input
            type="submit"
            value={Text.get('login_button_sign_in')}
            className="btn btn-default btn-flat btn-customizable c42-orange c42-button"
            disabled={isFormDisabled}
            data-test="submit"
          />
        </div>
        <div className="form-control-wrapper link">
          <a href="#" onClick={this.resetForm} data-test="signin-different-account">
            {Text.get('sso_signin_different_account')}
          </a>
        </div>
      </form>
    );
  }
}
