import { connect } from 'react-redux';
import {
  onSubmitPasswordReset,
  onChangePassword,
  onChangeConfirmPassword,
} from '../modules/reset-password-data';
import ResetPassword from '../components/reset-password-form';
import StoreInterface from '../store-interface';
import { setInitialQueryParams } from '../modules/routing';

// this function takes the global store state and maps it onto properties to pass to the underlying component
const mapStateToProps = (state: StoreInterface) => {
  const resetPasswordData = state.resetPasswordData.toJS();

  return {
    password: resetPasswordData.password,
    confirmPassword: resetPasswordData.confirmPassword,
    error: resetPasswordData.error,
    isFormSubmitted: resetPasswordData.isUpdatingPassword,
  };
};

// connect wraps a component with a container
// the container is store aware, whereas the component just knows about the props that are given to it
export default connect(mapStateToProps, {
  onChangePassword,
  onChangeConfirmPassword,
  submitForm: onSubmitPasswordReset,
  setQueryParams: setInitialQueryParams,
})(ResetPassword);
