import * as React from 'react';
import Text from '../../../shared/Text';
import TextField from '@c42/ui/lib/text-field';
import FormValidationMessage from '../form-validation-message';
import { sendEmptyTextIfNull } from '../../utils/input-handlers';

type EmptyObject = Record<string, never>;

interface Props {
  clearUsernameParam: () => void;
  error: string | null;
  isFormDisabled: boolean;
  notification: string | null;
  onChangeUsername: React.FormEventHandler<any>;
  submitForm: () => void;
  username: string | null;
  usernameParam: string | null;
}

export default class LoginStepUsername extends React.Component<Props, EmptyObject> {
  componentDidMount() {
    const { clearUsernameParam, onChangeUsername, usernameParam } = this.props;

    if (usernameParam !== null) {
      onChangeUsername({ target: { value: usernameParam } } as any);
      clearUsernameParam();
      this.submitForm();
    }
  }

  submitForm = (e?: React.FormEvent<any>) => {
    if (e && 'preventDefault' in e) {
      e.preventDefault();
    }
    this.props.submitForm();
  };

  render() {
    const { error, username, onChangeUsername, isFormDisabled, notification } = this.props;

    const isInputDirty = username !== null;
    const isUsernameEmpty = !username;
    const hasNotification = !!notification;

    const errorText = isInputDirty && isUsernameEmpty ? Text.get('_validate_username_or_email') : '';

    return (
      <form onSubmit={this.submitForm} name="login-step1-form" data-test="login-step1-form" noValidate>
        {hasNotification && (
          <div className="success" data-test="notification">
            {notification}
          </div>
        )}

        <FormValidationMessage error={error} dataTest="error-msg" />

        <TextField
          autoFocus
          displayErrorInLabel
          disabled={isFormDisabled}
          className="form-control-wrapper"
          data-test="username"
          value={username || ''}
          errorText={errorText}
          autoComplete="off"
          id="username"
          floatingLabelText={Text.get('_placeholder_username_or_email')}
          onBlur={sendEmptyTextIfNull.bind(null, onChangeUsername, username)}
          onChange={onChangeUsername}
        />

        <div className="form-control-wrapper submit">
          <input
            type="submit"
            value={Text.get('login_button_sign_in')}
            className="btn btn-default btn-flat btn-customizable c42-orange c42-button"
            data-test="submit"
          />
        </div>
      </form>
    );
  }
}
