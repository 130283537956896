import * as Immutable from 'immutable';
import CoreClient from '@c42/core-client';
import { LOGIN_INDEX } from '../constants/routes';
import Redux from 'redux-thunk';
import Text from '../../shared/Text';
import { LOCATION_CHANGE, push } from 'react-router-redux';
import StoreInterface, { ResetPasswordData } from '../store-interface';
import { inputValueDispatcher } from '../utils/input-handlers';
import { resetForm } from './auth-data';

/////////////////////
//  Action Types   //
/////////////////////

// visibleForTesting
export const UPDATING_PASSWORD_RESET = 'login/reset-password-data/UPDATING_PASSWORD_RESET';
export const PASSWORD_RESET_UPDATE_RESPONSE = 'login/reset-password-data/PASSWORD_RESET_UPDATE_RESPONSE';
export const PASSWORD_RESET_UPDATE_ERROR = 'login/reset-password-data/PASSWORD_RESET_UPDATE_ERROR';
export const CREATING_PASSWORD_RESET = 'login/reset-password-data/CREATING_PASSWORD_RESET';
export const PASSWORD_RESET_CREATE_RESPONSE = 'login/reset-password-data/PASSWORD_RESET_CREATE_RESPONSE';
export const PASSWORD_RESET_CREATE_ERROR = 'login/reset-password-data/PASSWORD_RESET_CREATE_ERROR';
export const PASSWORD_RESET_INVALID_TOKEN = 'login/reset-password-data/PASSWORD_RESET_INVALID_TOKEN';
export const CHANGE_USERNAME = 'login/reset-password-data/CHANGE_USERNAME';
export const CHANGE_PASSWORD = 'login/reset-password-data/CHANGE_PASSWORD';
export const CHANGE_CONFIRM_PASSWORD = 'login/reset-password-data/CHANGE_CONFIRM_PASSWORD';

/////////////////////
//     Reducer     //
/////////////////////
const initialFormValues = {
  error: null,
  username: null,
  password: null,
  confirmPassword: null,
};

// this isn't the entire initialState, just the slice that this reducer cares about
// i.e., everything under state.authData
export const initialState = Immutable.fromJS(
  Object.assign(
    {
      isCreatingPasswordReset: false,
      isUpdatingPassword: false,
      notification: null,
    },
    initialFormValues,
  ) as ResetPasswordData,
);

// the reducer should not perform any async actions
export default (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return state.merge(initialFormValues);
    case UPDATING_PASSWORD_RESET:
      return state.merge({
        isUpdatingPassword: true,
        notification: null,
        error: null,
      });
    case PASSWORD_RESET_UPDATE_RESPONSE:
      return state.merge({
        isUpdatingPassword: false,
        notification: Text.get('login_password_reset_success'),
        error: null,
      });
    case PASSWORD_RESET_UPDATE_ERROR:
      return state.merge({
        isUpdatingPassword: false,
        notification: null,
        error: Text.get('login_password_reset_error'),
      });
    case CREATING_PASSWORD_RESET:
      return state.merge({
        isCreatingPasswordReset: true,
        notification: null,
        error: null,
      });
    case PASSWORD_RESET_CREATE_RESPONSE:
      return state.merge({
        isCreatingPasswordReset: false,
        notification: Text.get('_flash_password_reset_check_email'),
        error: null,
      });
    case PASSWORD_RESET_CREATE_ERROR:
      return state.merge({
        isCreatingPasswordReset: false,
        notification: null,
        error: null,
      });
    case CHANGE_USERNAME:
      return state.merge({
        username: action.username,
      });
    case CHANGE_PASSWORD:
      return state.merge({
        password: action.password,
      });
    case CHANGE_CONFIRM_PASSWORD:
      return state.merge({
        confirmPassword: action.confirmPassword,
      });
    case PASSWORD_RESET_INVALID_TOKEN:
      return state.merge({
        isUpdatingPassword: false,
        notification: null,
        error: Text.get('login_password_reset_invalid_token'),
      });
    default:
      return state;
  }
};

/////////////////////
// Action Creators //
/////////////////////

export function onSubmitPasswordReset(
  password: string,
): Redux.ThunkAction<Promise<any>, StoreInterface, void> {
  return (dispatch, getState) => {
    dispatch({ type: UPDATING_PASSWORD_RESET });
    const state: StoreInterface = getState();
    const routing = state.routing.toJS();
    const params = {
      token: routing.passwordResetTokenParam,
      password,
    };
    // if the user did a password reset
    // then while they were on the login screen pasted a new reset link
    // and attempted it again,
    // this was using the cached request from the first attempt
    return CoreClient.UserPasswordResetUpdate.update({ params, useCache: false }).then(
      () => {
        dispatch({ type: PASSWORD_RESET_UPDATE_RESPONSE });
        dispatch(push(LOGIN_INDEX));
      },
      (error) => {
        if (error?.responseJson?.[0]?.name === 'INVALID_TOKEN') {
          dispatch({ type: PASSWORD_RESET_INVALID_TOKEN });
        } else {
          dispatch({ type: PASSWORD_RESET_UPDATE_ERROR });
        }
      },
    );
  };
}

export function onRequestPasswordReset(
  username: string,
): Redux.ThunkAction<Promise<any>, StoreInterface, void> {
  return (dispatch) => {
    dispatch({ type: CREATING_PASSWORD_RESET });
    const params = { username };
    return CoreClient.UserPasswordResetRequest.create({ params, useCache: false }).then(
      () => {
        dispatch({ type: PASSWORD_RESET_CREATE_RESPONSE });
        resetForm()(dispatch);
      },
      () => {
        dispatch({ type: PASSWORD_RESET_CREATE_ERROR });
        resetForm()(dispatch);
      },
    );
  };
}

export const onChangeUsername: Redux.ThunkAction<void, StoreInterface, void> = inputValueDispatcher.bind(
  null,
  CHANGE_USERNAME,
  'username',
);
export const onChangePassword: Redux.ThunkAction<void, StoreInterface, void> = inputValueDispatcher.bind(
  null,
  CHANGE_PASSWORD,
  'password',
);
export const onChangeConfirmPassword: Redux.ThunkAction<void, StoreInterface, void> =
  inputValueDispatcher.bind(null, CHANGE_CONFIRM_PASSWORD, 'confirmPassword');
