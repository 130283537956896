import * as React from 'react';
import { Link } from 'react-router';
import Text from '../../shared/Text';
import LogoIcon from './logo-icon/LogoIcon';
import { FORGOT_PASSWORD_INDEX } from '../constants/routes';
import { LOGIN_SUPPORT_URL_INCYDR } from '../constants/urls';

export interface LoginWrapperProps {
  isForgotPasswordVisible: boolean;
}

export const ForgotPasswordLink: React.StatelessComponent = () => (
  <div className="forgot-password-link">
    <Link data-test="forgot-pw-link" to={FORGOT_PASSWORD_INDEX}>
      {Text.get('login_forgot_password')}
    </Link>
  </div>
);

const LoginWrapper: React.StatelessComponent<LoginWrapperProps> = (props) => {
  const { isForgotPasswordVisible, children, ...rest } = props;

  return (
    <div className="c42-login-wrapper fade-in one">
      <div className="c42-login-box" data-test="loginbox">
        <div className="c42-login-body">
          <div className="brandings">
            <LogoIcon />
          </div>
          <div>{children && React.cloneElement(children as JSX.Element, rest)}</div>
        </div>
        <div className="c42-login-footer">
          <span>
            <span>{Text.get('login_support_need_help')}</span>&nbsp;
            <a href={LOGIN_SUPPORT_URL_INCYDR} target="_blank" rel="noopener noreferrer">
              {Text.get('login_support_learn_more')}
            </a>
          </span>
          {isForgotPasswordVisible && <ForgotPasswordLink />}
        </div>
      </div>
    </div>
  );
};

export default LoginWrapper;
