import * as React from 'react';
import Text from '../../shared/Text';
import FormValidationMessage from './form-validation-message';
import TextField from '@c42/ui/lib/text-field';
import { sendEmptyTextIfNull } from '../utils/input-handlers';

function submitHandler(props: ForgotPasswordProps, e: React.FormEvent<any>) {
  e.preventDefault();
  const { submitForm, username } = props;
  submitForm(username);
}

function resetHandler(props: ForgotPasswordProps, e: React.MouseEvent<any>) {
  e.preventDefault();
  const { resetForm } = props;
  resetForm();
}

interface ForgotPasswordProps {
  error: string | null;
  isFormSubmitted: boolean;
  onChangeUsername: React.FormEventHandler<any>;
  resetForm: () => void;
  submitForm: (username: string) => void;
  username: string | null;
}

const ForgotPassword: React.StatelessComponent<ForgotPasswordProps> = (props) => {
  const { error, isFormSubmitted, username, onChangeUsername } = props;
  const isUsernameEmpty = !username;
  const isUsernameInvalid = username != null && isUsernameEmpty;
  const isFormValid = !isUsernameEmpty;

  let usernameErrorText;
  if (isUsernameInvalid && isUsernameEmpty) {
    usernameErrorText = Text.get('_validate_username_or_email');
  }

  return (
    <form
      onSubmit={submitHandler.bind(this, props)}
      className="forgot-password-form"
      name="forgotPasswordFrm"
      noValidate
      data-test="forgot-password"
    >
      <FormValidationMessage error={error} />
      <div className="reset-password-message">{Text.get('login_reset_password')}</div>
      <TextField
        autoFocus
        displayErrorInLabel
        autoComplete="off"
        className="form-control-wrapper"
        data-test="forgot-password-username"
        disabled={isFormSubmitted}
        value={username || ''}
        errorText={usernameErrorText}
        floatingLabelText={Text.get('_placeholder_username_or_email')}
        onBlur={sendEmptyTextIfNull.bind(null, onChangeUsername, username)}
        onChange={onChangeUsername}
      />
      <div className="form-control-wrapper submit">
        <input
          type="submit"
          value={Text.get('login_reset_password')}
          disabled={!isFormValid}
          className="btn btn-default btn-flat btn-customizable c42-orange c42-button"
          data-test="forgot-password-submit"
        />
      </div>
      <div className="link">
        <a href="#" data-test="forgot-password-back-to-signin" onClick={resetHandler.bind(this, props)}>
          {Text.get('login_back_to_sign_in')}
        </a>
      </div>
    </form>
  );
};

export default ForgotPassword;
