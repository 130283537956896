import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { hashHistory } from 'react-router';
import { routerMiddleware } from 'react-router-redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';

// Reducers - note: These import names are used to set state containers
import authData from './modules/auth-data';
import resetPasswordData from './modules/reset-password-data';
import mainData from './modules/main-data';
import ssoData from './modules/sso-data';
import routing from './modules/routing';

// now combine the reducers back together and create the store
// the final initial state will take the shape of the reducers and their output
const rootReducer = combineReducers({
  authData,
  mainData,
  resetPasswordData,
  ssoData,
  routing,
});

const devMiddleware = [];

if (process.env.NODE_ENV === 'development') {
  devMiddleware.push(createLogger({ collapsed: true }));
}

// the thunkMiddleware allows our actions to be promises or functions that return the action
// the routerMiddleware allows you to dispatch navigation events
const middleware = [thunkMiddleware, routerMiddleware(hashHistory), ...devMiddleware];
const composedMiddleware = compose(applyMiddleware(...middleware));

// if the user has the Redux Dev Tools browser extension installed then enhance the store
// if ((<any> window).devToolsExtension) {
//  composedMiddleware = compose(composedMiddleware, (<any> window).devToolsExtension());
// }

export default createStore(rootReducer, composedMiddleware);
